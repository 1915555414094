<template>
  <v-container fluid class="fluid">
    <h1 class="text-left product-title" v-html="$t('contract_edit_header')"></h1>

    <!--     Vertragsdetails Form-->
    <v-form ref="editContractForm" id="editContractForm">
      <v-row>
        <v-col sm="12" md="6" lg="4" xl="3">
          <!--       Vertragsdetail-Typ Tierart     -->
          <v-text-field v-model="this.contractForm['contractualAspect' + this.$getUpLangKey()]" :label="$t('contract_details')" disabled></v-text-field>
          <v-text-field v-model="this.contractForm.animalType[this.$getLangKey()]" :label="$t('contracts_animal_type')" disabled></v-text-field>
          <!--    Vertrag von-->
          <dbmDatePicker v-model="contractForm.validFrom" :clearable="true" :dateLabel="$t('contract_edit_valid_from')"></dbmDatePicker>
          <!--    Vertrag bis-->
          <dbmDatePicker v-model="contractForm.validUntil" :dateLabel="$t('contract_edit_valid_until')" :clearable="true"></dbmDatePicker
          ><!-- Spezifische Inputs -->
          <!-- Inkassostelle -->
          <v-select
            v-if="contractForm.contractualAspect === 'COLLECTION'"
            id="contract_edit_encashment-organisation-production"
            v-model="contractForm.aspectValue"
            :label="$t('contract_edit_encashment_organisation')"
            :items="productionEncashmentOrganisations"
            item-title="name"
            item-value="id"
            clearable
            :loading="this.loadingProductionEncashmentOrganisations"
          />
          <!-- Nummer -->
          <v-text-field
            v-if="contractForm.contractualAspect === 'NUMBER'"
            v-model="contractForm.aspectValue"
            :label="$t('contract_edit_number')"
            type="number"
            clearable
          ></v-text-field>
          <!-- Sammelstelle -->
          <v-text-field
            v-if="contractForm.contractualAspect === 'COLLECTION_POINT' && $getBeta()"
            class="beta"
            id="contract_edit_sammelstelle"
            v-model="contractForm.aspectValue"
            :label="$t('contract_edit_sammelstelle')"
            type="number"
            clearable
          />
          <!-- Gehalt -->
          <v-select
            v-if="contractForm.contractualAspect === 'CONTENT'"
            id="contract_edit_gehalt"
            v-model="contractForm.aspectValue"
            :label="$t('contract_edit_gehalt')"
            :items="content"
            item-title="text"
            item-value="value"
            clearable
          />
          <!-- Ermächtigung -->
          <v-select
            v-if="contractForm.contractualAspect === 'EMPOWERMENT'"
            id="contract_edit_ermaechtigung"
            v-model="contractForm.aspectValue"
            :label="$t('contract_edit_ermaechtigung')"
            :items="content"
            item-title="text"
            item-value="value"
            clearable
          />
        </v-col>
        <v-col sm="12" md="6" lg="4" xl="3">
          <p>
            {{ contractForm.dairy ? contractForm.dairy.name + ', ' + contractForm.dairy.locality : '' }}
          </p>
          <p>
            {{
              $t('contract_edit_changed') +
              ': ' +
              (contractForm.modifierName ? contractForm.modifierName : '-') +
              ', ' +
              $moment(contractForm.modification).format('DD.MM.YYYY HH:mm')
            }}
          </p>
          <p>
            {{
              $t('contract_edit_created') +
              ': ' +
              (contractForm.creatorName ? contractForm.creatorName : '-') +
              ', ' +
              $moment(contractForm.creation).format('DD.MM.YYYY HH:mm')
            }}
          </p>
        </v-col>
        <v-alert v-model="showErrorMessage" class="wrong-file-type-alert" color="red" type="error" density="compact" dismissible>
          {{ errorMessage }}
        </v-alert>
      </v-row>
      <v-btn id="btn-edit" class="m-2" color="primary" :loading="this.loading" @click="editContract">
        <span v-html="$t('contract_edit_save')" />
      </v-btn>
      <v-btn id="btn-reset" class="m-2" color="secondary" @click="reset">
        <span v-html="$t('contract_edit_back')" />
      </v-btn>
      <v-btn id="btn-delete" class="m-2" color="secondary" :loading="this.loadingDelete" @click="editContract('delete')">
        <span v-html="$t('contract_edit_delete')" />
      </v-btn>
    </v-form>
  </v-container>
</template>

<script lang="ts">
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { useOrganisationsStore } from '@/store/enums/OrganisationsStore'
import { showError } from '@/services/axios'
import { apiURL, emitter } from '@/main'
import _ from 'lodash'
import { format, addMonths, addDays } from 'date-fns'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'contract_edit',
  components: { DbmDatePicker: DbmDatePicker },
  data() {
    return {
      contractForm: {
        validFrom: '',
        validUntil: '',
        contractualAspect: '',
        animalType: ''
      },
      showErrorMessage: false,
      errorMessage: undefined,
      loadingAspect: false,
      loading: false,
      loadingDelete: false,
      content: [
        { text: this.$t('contracts_edit_yes'), value: 1 },
        { text: this.$t('contracts_edit_no'), value: 0 }
      ]
    }
  },

  computed: {
    productionEncashmentOrganisations() {
      return useOrganisationsStore().productionEncashmentOrganisations
    },
    loadingProductionEncashmentOrganisations() {
      return useOrganisationsStore().loadingproductionEncashmentOrganisations
    }
  },
  methods: {
    async loadContract(contractId) {
      this.loadingAspect = true
      try {
        const result = await this.axios.get(apiURL + '/contracts/' + contractId, { headers: { 'Content-Type': 'application/json' } })
        if (await result) {
          this.contractForm = result.data
          return result.data
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loadingAspect = false
      }
    },
    async editContract(type) {
      if (type === 'delete') {
        this.contractForm.validUntil = format(addDays(addMonths(this.contractForm.validFrom, 1), -1), 'yyyy-MM-dd')
        this.loadingDelete = true
      } else {
        this.loading = true
      }
      let sendForm = _.omit(this.contractForm, ['farm.formattedAddress'])
      try {
        const response = await this.axios.patch(
          apiURL + '/contracts/' + this.contractForm.id,
          {
            ...sendForm
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (response.status === 200) {
          if (type !== 'delete') {
            emitter.emit('toastSuccess', {
              message: this.$t('toast_save_success_message')
            })
            await this.reset()
          } else {
            emitter.emit('toastSuccess', {
              message: this.$t('toast_generic_success_message')
            })
          }
        }
      } catch (e) {
        this.showErrorMessage = true
        this.errorMessage = showError(e, 'inline')
      } finally {
        this.loading = false
        this.loadingDelete = false
      }
    },
    async reset() {
      this.goToContracts()
    },
    goToContracts() {
      this.$router.go(-1)
    }
  },
  beforeMount() {
    useOrganisationsStore().fetchProductionEncashmentOrganisations()
  },
  async mounted() {
    const contractId = this.$route.params.id
    this.contractForm = await this.loadContract(contractId)
  }
})
</script>
